import React, { useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { List, Avatar, Affix } from "antd";
import { Link } from "react-router-dom";
import { useSaveStatusContext } from "../LearningUnitBuilder/LearningUnitSaveStatusContext";
import { AUTOSAVE_STATUS } from "../../../constants/types";
import {
  ShadowHeader,
  Hamburger,
  TrailingItem,
  Popover,
  Button,
  Name,
  IconContainer,
  PopIcon,
  PopDivider,
  HomeIcon,
  AppIcon,
  VersionIcon,
} from "./styled";
import { GET_USER_NAME } from "./queries";
import { EXECUTE_ANONYMOUS } from "./mutations";
import { useMediaQuery } from "../../../utils/hooks";
import { UpToMobileWidth } from "../../../constants/mediaQueries";
import { logout, getUserType } from "../../../utils/authHelpers";
import { assessmentPortalURL, teachersPortalURL } from "../../../../config";

const env = process.env.RUN_ENV;
const isLocalDev = env === "development";
const ASSESSMENT_PORTAL_URL = assessmentPortalURL[env];
const TEACHERS_PORTAL_URL = teachersPortalURL[env];

const PopoverContent = ({ executeAnonymous }) => {
  const { Item } = List;
  const userType = getUserType();
  return (
    <>
      <Link to="/">
        <Item>
          <PopIcon type="home" />
          Home
        </Item>
      </Link>
      <PopDivider />
      {userType.includes("Student") && (
        <a href={ASSESSMENT_PORTAL_URL}>
          <Item>
            <PopIcon type="switcher" />
            Switch to Learning Portal
          </Item>
        </a>
      )}
      {userType.includes("Teacher") && (
        <a href={TEACHERS_PORTAL_URL}>
          <Item>
            <PopIcon type="switcher" />
            Switch to Teachers Portal
          </Item>
        </a>
      )}
      <Link to="#" onClick={() => logout()}>
        <Item>
          <PopIcon type="logout" />
          Sign out
        </Item>
      </Link>
      {isLocalDev && (
        <>
          <PopDivider />
          <Link to="#" onClick={executeAnonymous}>
            <Item>
              <PopIcon type="bug" />
              Execute Anonymous
            </Item>
          </Link>
        </>
      )}
    </>
  );
};

const renderHamburgerButton = () => {
  return (
    <IconContainer>
      <Hamburger />
    </IconContainer>
  );
};

const LearningUnitLinks = ({ subjectId, toggleVersion, toggleShowComments }) => {
  const { saveStatus, dispatchSaveStatus } = useSaveStatusContext();
  const toggleVersionLink = () => {
    toggleVersion();
    toggleShowComments();
  };
  return (
    <>
      <IconContainer as={Link} to={`/sbj${subjectId}`}>
        <AppIcon />
      </IconContainer>
      {saveStatus === AUTOSAVE_STATUS.SUCCESS && (
        <IconContainer as={Link} to={"#"} onClick={toggleVersion}>
          <VersionIcon />
        </IconContainer>
      )}
    </>
  );
};

const HeaderLinks = ({ subjectId, toggleVersion, toggleShowComments }) => (
  <>
    <IconContainer as={Link} to="/">
      <HomeIcon />
    </IconContainer>
    {subjectId && <LearningUnitLinks subjectId={subjectId} toggleVersion={toggleVersion} toggleShowComments={toggleShowComments} />}
  </>
);

const UserProfile = () => {
  const { loading, error, data } = useQuery(GET_USER_NAME);
  const [executeAnonymous] = useMutation(EXECUTE_ANONYMOUS);
  const isMobile = useMediaQuery(UpToMobileWidth);

  if (loading) return "";
  if (error) return "";
  const username = data.getUserName;
  return (
    <TrailingItem>
      <Name>{!isMobile && username}</Name>
      <Popover title={isMobile && username} content={<PopoverContent executeAnonymous={executeAnonymous} />}>
        <Button>
          <Avatar icon={"user"} />
        </Button>
      </Popover>
    </TrailingItem>
  );
};

const Header = ({ collapsedMenu, toggleCollapsed, fixed, subjectId, toggleVersion, toggleShowComments, showVersionIcon }) => {
  return (
    <div>
      {/* <div
        style={{
          width: "100%",
          backgroundColor: "red",
          color: "white",
          textAlign: "center",
          fontWeight: "bolder",
          position: "fixed",
          zIndex: "1000",
        }}
      >
        The site will be down for maintenance from Wed 7 July, 6:00pm AEDT to Wed 7 July, 11:59pm AEDT.
      </div> */}
      <ShadowHeader
        title={
          <HeaderLinks
            subjectId={subjectId}
            toggleVersion={toggleVersion}
            showVersionIcon={showVersionIcon}
            toggleShowComments={toggleShowComments}
          />
        }
        ghost={false}
        backIcon={renderHamburgerButton()}
        subTitle={<UserProfile />}
        onBack={toggleCollapsed}
        fixed={fixed}
        collapsedMenu={collapsedMenu}
      />
    </div>
  );
};
export default Header;
